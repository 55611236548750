












import useModelValue from '@/@core/helpers/modelValue';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'KExpansionPanels',
  setup(props, ctx) {
    const modelValue = useModelValue(ctx);

    return {
      modelValue
    };
  }
});
